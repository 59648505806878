import { Suspense } from 'react';

import type { RouteId } from '@trello/router';
import { useRouteParams } from '@trello/router';
import { defaultRouter_DO_NOT_USE } from '@trello/router/legacy-router';
import { useLazyComponent } from '@trello/use-lazy-component';

export const LazyPowerUpDirectory = () => {
  const route = defaultRouter_DO_NOT_USE.getRoute();
  const query = route.url?.searchParams.get('q') || '';
  const routeParams =
    useRouteParams<typeof RouteId.POWER_UP_PUBLIC_DIRECTORY>();
  const PowerUpDirectory = useLazyComponent(
    () =>
      import(/* webpackChunkName: "power-up-directory" */ './PowerUpDirectory'),
    {
      namedImport: 'PowerUpDirectory',
    },
  );
  return (
    <Suspense fallback={null}>
      <PowerUpDirectory
        section={routeParams.section}
        subsection={routeParams.subsection}
        query={query}
      />
    </Suspense>
  );
};
